<template>
  <div class="bgImage centerAll">
    <div
      :class="{loginAndRegisterContainer: $screen.tablet,
               loginAndRegisterContainerPhone: !$screen.tablet}"
    >
      <a
        class="material-card-content centerAll"
        :href="$websiteUrl"
      >
        <img
          width="300px"
          src="@/assets/img/logoWhite.svg"
          alt="Boardgent logo"
        >
      </a>
      <card external-card="card container material-card card-form-container">
        <form
          slot="Pre-header"
          action=""
          @submit.prevent="validateBeforeSubmit"
        >
          <card
            class="material-card-content"
            url-help=""
          >
            <section
              slot="header"
              class="modal-card-body"
            >
              <p
                style="margin-top: 12px;"
                class="title"
              >
                Create your Boardgent account
              </p>
              <b-notification
                :active="error != undefined"
                :closable="false"
                class="errorBanner"
                type="is-danger"
              >
                {{ error != undefined ? error : '' }}
              </b-notification>
              <div class="columns mb-0">
                <b-field
                  :type="errors.has('firstName') ? 'is-danger':''"
                  label="First name"
                  class="column mb-0 field-column"
                >
                  <b-input
                    id="firstName"
                    v-model="firstName"
                    v-validate="'required'"
                    name="firstName"
                    type="text"
                    placeholder="Your first name"
                    focus
                    @focus="updateValuesFromHtml"
                  />
                  <span
                    v-show="errors.has('firstName')"
                    class="help is-danger"
                  >The first name field is required </span>
                </b-field>
                <b-field
                  :type="errors.has('lastName') ? 'is-danger':''"
                  label="Last name"
                  class="column mb-0 field-column"
                >
                  <b-input
                    id="lastName"
                    v-model="lastName"
                    v-validate="'required'"
                    name="lastName"
                    type="text"
                    placeholder="Your last name"
                    @focus="updateValuesFromHtml"
                  />
                  <span
                    v-show="errors.has('lastName')"
                    class="help is-danger"
                  >The last name field is required</span>
                </b-field>
              </div>
              <b-field
                :type="errors.has('email') ? 'is-danger':''"
                label="Work email"
              >
                <b-input
                  id="email"
                  v-model="email"
                  v-validate="'required'"
                  :disabled="disabled"
                  name="email"
                  type="email"
                  placeholder="Your email"
                  @focus="updateValuesFromHtml"
                />
              </b-field>
              <span
                v-show="errors.has('email')"
                class="help is-danger"
              >{{ errors.first('email') }}</span>

              <b-field
                :type="errors.has('password') ? 'is-danger':''"
                label="Password"
              >
                <b-input
                  id="password"
                  v-model="password"
                  v-validate="'required|min:6'"
                  name="password"
                  type="password"
                  :password-reveal="password != ''"
                  placeholder="Create a password"
                  autocomplete="new-password"
                  @focus="updateValuesFromHtml"
                />
              </b-field>
              <span
                v-show="errors.has('password')"
                class="help is-danger"
              >{{ errors.first('password') }}</span>
            </section>
            <hr class="footerLine">
            <div
              slot="footer"
              class="modal-card-body"
            >
              <button
                :class="{'is-loading': isLoading }"
                class="button is-primary main-card-form-button"
              >
                Create account
              </button>
              <p>
                By creating the account, you agree to our
                <a
                  href="https://boardgent.com/terms-of-service"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                and
                <a
                  href="https://boardgent.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
          </card>
        </form>
      </card>
      <div>
        <b-button
          v-if="!invited"
          expanded
          to="/login"
          class="button"
          tag="router-link"
          type="is-light"
        >
          Have an account? Sign in
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import card from '@/components/cross/Card.vue';

import toastMessage from '@/helpers/toastMessage';
import EnvironmentSetter from '@/helpers/environmentSetter';

import analyticsMixin from '@/mixins/analytics';

import vtulEnums from '../../../cross/index';

const { operationalApiErrors, programmerApiErrors } = vtulEnums.enum.api;
const userEnums = vtulEnums.enum.user.origin;
const { managementAction } = vtulEnums.enum.authentication;

export default {
  name: 'Register',
  metaInfo: {
    title: 'Register',
  },
  components: {
    card,
  },
  mixins: [analyticsMixin],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      lang: navigator.language,
      origin: userEnums.REGISTER_PAGE.value.dbValue,
      disabled: false,
      invited: false,
      isLoading: false,
      error: undefined,
      apiUrl: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_IP_API_URL'),
    };
  },
  async created() {
    if (this.$route.params.token && this.$route.params.companyId) {
      const invite = await this.findInvitation({
        query: {
          $select: ['email'],
          token: this.$route.params.token,
          companyId: this.$route.params.companyId,
          $limit: 1,
          allowUnauthenticated: true,
        },
      });
      if (invite && invite.data && invite.data[0]) {
        this.email = invite.data[0].email;
        this.disabled = true;
        this.invited = true;
        this.origin = userEnums.INVITATION.value.dbValue;
      }
      return;
    }
    if (this.$route.query.source || this.$route.query.medium || this.$route.query.campaign
        || this.$route.query.term || this.$route.query.content) {
      this.origin = userEnums.CAMPAIGNS.value.dbValue;
    }
  },
  methods: {
    updateValuesFromHtml() {
      // The @input and @change events are not called on some iOS browsers when tools like 1Password
      // fill out the login form, so the html has the right value but the v-model is empty
      // here we fill the v-model with the html value
      const firstNameInput = document.getElementById('firstName');
      const lastNameInput = document.getElementById('lastName');
      const emailInput = document.getElementById('email');
      const passwordInput = document.getElementById('password');
      this.firstName = firstNameInput ? this.capitalizeFirstLeter(firstNameInput.value) : '';
      this.lastName = lastNameInput ? this.capitalizeFirstLeter(lastNameInput.value) : '';
      this.email = emailInput ? emailInput.value : this.email;
      this.password = passwordInput ? passwordInput.value : this.password;
    },
    async validateBeforeSubmit() {
      this.updateValuesFromHtml();
      const result = await this.$validator.validateAll();
      if (result) {
        const country = await this.fetchUserCountry();
        this.register(this.firstName, this.lastName, this.email.toLowerCase(),
          this.password, this.lang, country, this.origin);
      }
    },
    register(name, lastName, email, password, lang, country, origin) {
      this.isLoading = true;
      // Automatically log the user in after successful signup.
      this.createUser({
        name,
        lastName,
        email,
        password,
        lang,
        country,
        enabledInDatabase: true,
        origin,
        utm: {
          source: this.$route.query.source,
          medium: this.$route.query.medium,
          campaign: this.$route.query.campaign,
          term: this.$route.query.term,
          content: this.$route.query.content,
        },
      })
        .then((user) => {
          this.trackEvent(true, 'track', 'Sign Up', {
            email,
            origin,
          });
          this.authenticate({ strategy: 'local', email, password }).then(() => {
            if (this.invited) {
              this.assignMembership({
                action: 'assignMembership',
                userId: user.id,
                companyId: this.$route.params.companyId,
                token: this.$route.params.token,
                email,
              }).then(() => {
                this.$router.push(`/${this.$route.params.companyId}/devices`);
              }).catch((error) => {
                this.logout();
                if (error && error.code
                && error.code === operationalApiErrors.INVALID_EMAIL_FOR_INVITATION.value.code) {
                  this.$router.push(`/companyinvite/${operationalApiErrors.INVALID_EMAIL_FOR_INVITATION.value.code}`);
                } else {
                  this.$router.push(`/companyinvite/${programmerApiErrors.GENERAL_ERROR.value.code}`);
                }
                throw error;
              });
            } else {
              this.$router.push('createcompany');
            }
            this.sendVerifyEmail(this.email.toLowerCase());
          }).catch(() => {
            this.isLoading = false;
            this.$router.push('login');
          });
        })
        .catch((errorParam) => {
          this.error = errorParam.message;
          this.isLoading = false;
        });
    },
    async sendVerifyEmail(email) {
      this.error = undefined;
      try {
        const response = await this.sendVerifyToken({
          action: managementAction.VERIFY_EMAIL.value,
          email,
        });
        if (response) {
          toastMessage.showSuccess('Check your mailbox to verify your email. If the confirmation email doesn’t appear within a few minutes, check your spam folder.', 8000);
        } else {
          this.error = { message: 'Unexpected error, please try again later' };
        }
      } catch (errorParam) {
        this.error = errorParam.message;
      }
    },

    async fetchUserCountry() {
      try {
        const result = await this.axios.get(this.apiUrl);
        if (result.status === 200) {
          return result.data.country;
        }
      } catch (error) {
        /* Err */
      }
      return '';
    },

    capitalizeFirstLeter(text) {
      const splitedText = text.split(' ');
      let finalText = '';
      splitedText.forEach((currentText) => {
        finalText += `${currentText.charAt(0).toUpperCase()}${currentText.slice(1)} `;
      });
      return finalText.trim();
    },

    ...mapActions('invitations', { findInvitation: 'find' }),
    ...mapActions('user', { createUser: 'create' }),
    ...mapActions('auth', ['authenticate', 'logout']),
    ...mapActions('company-management', { assignMembership: 'create' }),
    ...mapActions('auth-management', { sendVerifyToken: 'create' }),
  },
};

</script>

<style scoped>
.loginAndRegisterContainer{
  width:525px;
  padding-bottom: 1rem;
}

.loginAndRegisterContainerPhone{
  width:95%;
  padding-bottom: 1rem;
}

.card-form-container {
  max-width: 750px;
}
</style>

<style>
  .field-column .field {
    display: flex;
    flex-direction: column;
  }
</style>
